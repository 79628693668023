@mixin screen() {
  @media screen and (min-width: 760px) {
    @content;
  }
}
:root {
  --color__background: hsl(0, 0%, 7%);
  --color__primary: hsl(0, 0%, 91%);
  --color__accent: hsl(31, 98%, 75%);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  color: var(--color__primary);
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  line-height: 1.75em;
  font-size: 3vw;
  font-weight: 400;
  @include screen {
    font-size: 1.3vw;
  }
}
body {
  min-width: 100vw;
  overflow-x: hidden;
  background-color: var(--color__background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app {
  margin: 0 auto;
  padding: 10vw;
  @include screen {
    padding: 15vw;
  }
}
h1 {
  line-height: 1.3;
}
a {
  color: var(--color__accent);
  &:hover {
    opacity: 0.8;
  }
}

.logo {
  max-width: 50%;
  color: #fec283;
  @include screen {
    max-width: 25%;
    margin-bottom: 10%;
  }
}
